
var ratyMal = [],
    ratyRow = [],
    liczbaRat = 0,
    sumMal = [0, 0, 0],
    sumRow = [0, 0, 0];

(function (document, window, $) {
    'use strict';

    $('#createPdf').prop('disabled', true);
    $('#createPdf').addClass('disabled');
    var Site = window.Site;
    $(document).ready(function () {
        Site.run();

        $('#n_build').on('click', function(){
            $('#n_value').prop('disabled', function(i, v) { return !v; });
            CALC.run();
        });
        $('#n_n').on('click', function(){
            if($(this).is(':checked')) {
                $('.n_row').css('opacity', '1');
                $('#k_calc').parent().show();
                $('.n_row').find('input').prop('disabled', false);
                if(!$('#n_build').is(':checked'))
                    $('#n_value').prop('disabled', true);

            } else {
                $('.n_row').css('opacity', '0.5');
                $('.n_row').find('input').prop('disabled', true);
                $('#k_calc').val(3).trigger("change");
                $('#k_calc').parent().hide();
                $('#k_amount').show();
            }
        });
        $('input[name=k_interest_type]').on('click', function(){
            $('#k_interest').val(parseFloat(parseFloat($('#k_rate').val()) + parseFloat($('#k_margin').val())).toFixed(2));
            if($('#inputRadiosUnchecked').is(':checked')) {
               $('#inputRadiosUnchecked2_group').find('input').prop('disabled', true);
               $('#inputRadiosUnchecked_group').find('input').prop('disabled', false);
               $('#inputRadiosUnchecked_group').show();

            } else {
                $('#inputRadiosUnchecked2_group').find('input').prop('disabled', false);
                $('#inputRadiosUnchecked_group').find('input').prop('disabled', true);
                $('#inputRadiosUnchecked_group').hide();
            }
        });
        $('#k_rate').on('change', function(){
            $('#k_interest').val(parseFloat(parseFloat($('#k_rate').val()) + parseFloat($('#k_margin').val())).toFixed(2));
        });
        $('#k_margin').on('keyup', function(){
            $('#k_interest').val(parseFloat(parseFloat($('#k_rate').val()) + parseFloat($('#k_margin').val())).toFixed(2));
        });
        $('#k_add_cost').on('click', function(){
            $('.k_cost_group:first').clone().insertAfter($('.k_cost_group:last'));
            $('.k_cost_group:last').show();
            $('.wb-close-mini').on('click', function(){
                $(this).parent().parent().remove();
            });
            $('.k_cost_group:last').find('select').selectpicker();
            $('input').not('.k_name').keyup(function(){

                if($(this).val() == "NaN" && !$(this).hasClass('k_name') ) {
                    $(this).val("0.00");
                }
                CALC.run();
            });

            $('.k_credited').click(function(){
                CALC.run();
            });

            $('.k_value_type').on('change', function(){
                CALC.run();
            });

            $('input').not('.k_name').focusout(function(){

                var tt = $(this).val();
                tt = tt.replace(' ', '');
                tt = tt.replace(/,/g, '.');
                var array = tt.split(".");
                tt = array[0];
                if(array.length > 1)
                    tt += "." + array[1].substring(0, 2);
                if($(this).attr('id') != 'k_period')
                    tt = parseFloat(tt).toFixed(2)
                tt = numberWithCommas(tt);
                $(this).val(tt);
                $(this).trigger('keyup');
            });
            $("input").keydown(function (e) {
                if(!$(this).hasClass('k_name')) {
                    // Allow: backspace, delete, tab, escape, enter and .
                    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 188, 190]) !== -1 ||
                            // Allow: Ctrl+A
                        (e.keyCode == 65 && e.ctrlKey === true) ||
                            // Allow: Ctrl+C
                        (e.keyCode == 67 && e.ctrlKey === true) ||
                            // Allow: Ctrl+X
                        (e.keyCode == 88 && e.ctrlKey === true) ||
                            // Allow: home, end, left, right
                        (e.keyCode >= 35 && e.keyCode <= 39)) {
                        // let it happen, don't do anything
                        return;
                    }
                    // Ensure that it is a number and stop the keypress
                    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                        e.preventDefault();
                    }
                }
            });
        });



        $('#k_calc').on('change', function(){
            CALC.run();
        });
        $('#k_period_type').on('change', function(){
            CALC.run();
        });

        $('#createPdf').on('click', function(){
            CALC.createPDF();
        });

        $('#set_extended').click(function(){
            CALC.run();
        });

        $('input[type="text"]').not('.k_name').keyup(function(){

            if($(this).val() == "NaN" && !$(this).hasClass('k_name') ) {
                $(this).val("0.00");
            }
            CALC.run();
        });

        $("input[type='text']").keydown(function (e) {
            if(!$(this).hasClass('k_name')) {
                // Allow: backspace, delete, tab, escape, enter and .
                if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 188, 190]) !== -1 ||
                        // Allow: Ctrl+A
                    (e.keyCode == 65 && e.ctrlKey === true) ||
                        // Allow: Ctrl+C
                    (e.keyCode == 67 && e.ctrlKey === true) ||
                        // Allow: Ctrl+X
                    (e.keyCode == 88 && e.ctrlKey === true) ||
                        // Allow: home, end, left, right
                    (e.keyCode >= 35 && e.keyCode <= 39)) {
                    // let it happen, don't do anything
                    return;
                }
                // Ensure that it is a number and stop the keypress
                if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                    e.preventDefault();
                }
            }
        });

        $('input[type="text"]').not('.k_name').focusout(function(){

            if($(this).val() == "NaN" && !$(this).hasClass('k_name') ) {
                $(this).val(0.00);
            }
            var tt = $(this).val();
            tt = tt.replace(' ', '');
            tt = tt.replace(/,/g, '.');
            var array = tt.split(".");
            tt = array[0];
            if(array.length > 1)
                tt += "." + array[1].substring(0, 2);
            if($(this).attr('id') != 'k_period')
                tt = parseFloat(tt).toFixed(2)
            tt = numberWithCommas(tt);
            $(this).val(tt);
            $(this).trigger('keyup');
        });

        $('.k_credited').click(function(){
            CALC.run();
        });

        //$('#set_first_12').click(function(){
        //    CALC.run();
        //});

        //$('#set_annual').click(function(){
        //    CALC.run();
        //});

        $('#set_mal').click(function(){
            if($(this).is(':checked')) {
                $('.r-mal').show();
            } else
                $('.r-mal').hide();
            CALC.run();
        });

        $('#set_row').click(function(){
            if($(this).is(':checked')) {
                $('.r-row').show();
            } else
                $('.r-row').hide();
            CALC.run();
        });

    });
})(document, window, jQuery);



var CALC = {
    run: function(){



        if($('#n_value').prop('disabled')) {
            $('#n_value').val($('#n_cost').val().split(" ").join(""));
        }

        switch($('#k_calc').val()) {
            case "1":
                $("#k_amount").parent().hide();
                $("#k_amount").val(Number($("#n_cost").val().split(" ").join("")) -  Number($('#n_input').val().split(" ").join("")));
                break;
            case "2":
                $("#k_amount").parent().hide();
                $("#k_amount").val(Number($('#n_value').val().split(" ").join("")) * 0.9);
                break;
            case "3":
                $("#k_amount").parent().show();
                break;
        }

        var costs = 0;
        var ownCosts = 0;

        $('.k_cost_group').each(function(){
            var subcost = 0;
            if($(this).find('.k_value_type').val().split(" ").join("") == 1) {
                subcost +=Number($(this).find('.k_value').val().split(" ").join(""));
            } else {
                subcost += Number($(this).find('.k_value').val().split(" ").join("")) * Number($("#k_amount").val().split(" ").join("")) / 100;
            }

            if($(this).find('.k_credited').is(':checked'))
                costs += subcost;
            else
                ownCosts += subcost;

        });


        $('#k_total').val(numberWithCommas(parseFloat(costs + Number($("#k_amount").val().split(" ").join(""))).toFixed(2)));
        $('#k_own').val(numberWithCommas(parseFloat(ownCosts).toFixed(2)));

        if($(this).is(':checked')) {
            if (Number($('#k_own').val().split(" ").join("")) > (Number($('#n_owned').val().split(" ").join("")) - Number($('#n_input').val().split(" ").join("")))) {
                $("#k_own").parent().addClass('has-warning');
                $("#k_own_error").show();
            } else {
                $("#k_own").parent().removeClass('has-warning');
                $("#k_own_error").hide();
            }

            if (parseFloat(costs + Number($("#k_amount").val().split(" ").join(""))).toFixed(2) > Number($('#n_value').val().split(" ").join("")) * 0.9) {
                $("#k_total").parent().addClass('has-warning');
                $("#k_total_error").show();
            } else {
                $("#k_total").parent().removeClass('has-warning');
                $("#k_total_error").hide();
            }
        }

      console.log(liczbaRat.length);
         liczbaRat = $('#k_period').val().split(" ").join("");
      console.log(liczbaRat.length);
        if($('#k_period_type').val().split(" ").join("") == 2)
            liczbaRat = parseFloat(liczbaRat) * 12;
      console.log(liczbaRat.length);
        ratyMal = [];
        ratyRow = [];
        var kwota = $('#k_total').val().split(" ").join("");
        var kwotaStart = $('#k_total').val().split(" ").join("");
        var oprocentowanie = $('#k_interest').val().split(" ").join("");
        sumMal = [0, 0, 0];
        sumRow = [0, 0, 0];

        for(var i = 0; i < liczbaRat; i++) {
            var rata = [];
            rata[1] = kwotaStart / liczbaRat;
            rata[2] = kwota * oprocentowanie / 1200;
            rata[0] = rata[1] + rata[2];
            rata[3] = kwota - rata[1];
            sumMal[2] += rata[2];
            sumMal[0] += rata[0];
            sumMal[1] += rata[1];
            kwota -= rata[1];
            ratyMal.push(rata);
        }

        var kwota = $('#k_total').val().split(" ").join("");
        var licznik = oprocentowanie/1200;

        for(var i = 0; i < liczbaRat; i++) {
            var rata = [];
            var mianownik = 1 - Math.pow(1+licznik, -liczbaRat);
            var r = kwotaStart*licznik/mianownik;
            rata[2] = kwota * oprocentowanie / 1200;
            rata[1] = r - rata[2];
            rata[0] = r;
            rata[3] = kwota - rata[1];
            sumRow[2] += rata[2];
            sumRow[0] += rata[0];
            sumRow[1] += rata[1];
            ratyRow.push(rata);

            kwota -= rata[1];
        }

        console.log(liczbaRat.length);
        if(liczbaRat.length > 0) {
            $('#createPdf').prop('disabled', false);
            $('#createPdf').removeClass('disabled');
        } else {
            $('#createPdf').prop('disabled', true);
            $('#createPdf').addClass('disabled');
        }

        $('#table_schedule tbody tr').remove();
        for(var i = 0; i < liczbaRat; i++) {
                if(($('#set_extended').is(':checked') && (i%12==0 || i < 12)) || (!$('#set_extended').is(':checked') && i < 12)) {
                    var appnd = '<tr><td class="text-center">'+(i+1)+'</td>';
                    if($('#set_mal').is(':checked')) {
                        appnd += '<td class="r-mal">' + numberWithCommas(parseFloat(ratyMal[i][0]).toFixed(2)) + ' zł</td>';
                        appnd += '<td class="r-mal">' + numberWithCommas(parseFloat(ratyMal[i][1]).toFixed(2)) + ' zł</td>';
                        appnd += '<td class="r-mal">' + numberWithCommas(parseFloat(ratyMal[i][2]).toFixed(2)) + ' zł</td>';
                        appnd += '<td class="r-mal">' + numberWithCommas(parseFloat(ratyMal[i][3]).toFixed(2)) + ' zł</td>';
                    }

                    if($('#set_row').is(':checked')) {
                        appnd += '<td class="r-row">' + numberWithCommas(parseFloat(ratyRow[i][0]).toFixed(2)) + ' zł</td>';
                        appnd += '<td class="r-row">' + numberWithCommas(parseFloat(ratyRow[i][1]).toFixed(2)) + ' zł</td>';
                        appnd += '<td class="r-row">' + numberWithCommas(parseFloat(ratyRow[i][2]).toFixed(2)) + ' zł</td>';
                        appnd += '<td class="r-row">' + numberWithCommas(parseFloat(ratyRow[i][3]).toFixed(2)) + ' zł</td>';
                    }
                    appnd += '</tr>';
                    $('#table_schedule tbody').append(appnd);
                }
        }

        var appnd = '<tr class="active" style="font-weight: bold !important"><td>Suma (kredyt):</td>';

        if($('#set_mal').is(':checked')) {
            appnd += '<td class="r-mal">' + numberWithCommas(parseFloat(sumMal[0]).toFixed(2)) + ' zł</td>';
            appnd += '<td class="r-mal">' + numberWithCommas(parseFloat(sumMal[1]).toFixed(2)) + ' zł</td>';
            appnd += '<td class="r-mal">' + numberWithCommas(parseFloat(sumMal[2]).toFixed(2)) + ' zł</td>';
            appnd += '<td class="r-mal"></td>';
        }

        if($('#set_row').is(':checked')) {
            appnd += '<td class="r-row">' + numberWithCommas(parseFloat(sumRow[0]).toFixed(2)) + ' zł</td>';
            appnd += '<td class="r-row">' + numberWithCommas(parseFloat(sumRow[1]).toFixed(2)) + ' zł</td>';
            appnd += '<td class="r-row">' + numberWithCommas(parseFloat(sumRow[2]).toFixed(2)) + ' zł</td>';
            appnd += '<td class="r-row"></td>';
        }
        appnd += '</tr>';
        $('#table_schedule tbody').append(appnd);

    },
    createPDF: function(){
        var costs = [];
        console.log('tetete');

        $('.k_cost_group:visible').each(function(){
            var cost = [];
            cost.push($(this).find('.k_name').val());
            cost.push($(this).find('.k_value').val().split(" ").join(""));
            cost.push($(this).find('.k_value_type').val());
            cost.push($(this).find('.k_credited').is(':checked') ? 1 : 0);
            costs.push(cost);
        });

        console.log(costs);


        $.ajax({
            method: "POST",
            url: "/pdf",
            data: {
                n_cost: $('#n_cost').val().split(" ").join(""),
                n_build: $('#n_build').is(':checked') ? 1 : 0,
                n_value: $('#n_value').val().split(" ").join(""),
                n_owned: $('#n_owned').val().split(" ").join(""),
                n_input: $('#n_input').val().split(" ").join(""),
                k_calc: $('#k_calc').val().split(" ").join(""),
                k_amount: $('#k_amount').val().split(" ").join(""),
                k_period: $('#k_period').val().split(" ").join(""),
                k_interest: $('#k_interest').val().split(" ").join(""),
                k_period_type: $('#k_period_type').val().split(" ").join(""),
                k_own: $('#k_own').val().split(" ").join(""),
                k_total: $('#k_total').val().split(" ").join(""),
                costs: JSON.stringify(costs),
                set_row: $('#set_row').is(':checked') ? 1 : 0,
                set_mal: $('#set_mal').is(':checked') ? 1 : 0,
                k_interest_type: $('#inputRadiosUnchecked').is(':checked') ? 1 : 0,
                k_rate: $('#k_rate option:selected').text(),
                k_margin: $('#k_margin').val(),
                ratyMal: JSON.stringify(ratyMal),
                ratyRow: JSON.stringify(ratyRow),
                sumMal: JSON.stringify(sumMal),
                sumRow: JSON.stringify(sumRow),
                _token: $('#_token').val(),
                s_info: $('#s_info').val(),
                s_costs: $('#s_costs').val(),
                real_period: liczbaRat,
                //set_frequncy: $('#set_annual').is(':checked') ? 1 : 2,
                set_extended: $('#set_extended').is(':checked') ? 1 : 0,
                n_n: $('#n_n').is(':checked') ? 1 : 0
            }
        })
            .done(function( result ) {
                console.log( result );
                //window.location.href = result;
                $('#pdfLink').attr('href', result);
                $('#downloadPdf').modal('show');
                $('#createPdf').removeAttr('disabled');
                $('#createPdf').removeAttr('data-loading');

            });

    }
};




function numberWithCommas(n) {
    var parts=n.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ") + (parts[1] ? "." + parts[1] : "");
}

